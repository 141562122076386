
<template>
    <div class="siteInfo">
        <div class="tabs-wrap flex" :class="{'visit-hide': true}">
            <div class="tab-wrap" :class="{'active': tabindex == 0}" @click="tabindex = 0">销售信息</div>
            <div class="tab-wrap" :class="{'active': tabindex == 1}" @click="tabindex = 1">库存信息</div>
        </div>
        <div v-if="tabindex == 0">
            <div class="title">销售明细</div>
                    <div class="public-card stock-box"
                         v-for="(sell,stId) in sellList" :key="stId">
                        <div class="public-flex public-just-between m-b-15">
                            <div>{{sell.goodsName}}</div>
                            <div class="stock-num">{{sell.goodsNumber}}</div>
                        </div>
                    </div>
        </div>
        <div v-if="tabindex == 1">
            <div class="title">库存明细</div>
            <div class="public-card stock-box"
                 v-for="(stock,stId) in stockList" :key="stId">
                <div class="public-flex public-just-between m-b-15">
                    <div>{{stock.goodsName}}</div>
                    <div class="stock-num">{{stock.goodsNumber}}</div>
                </div>
            </div>
        </div>




    </div>
</template>

<script>
    import {getStationSellAndStockInfo} from '@/utils/putInto';
    export default {
        data() {
            return {
                sellList:[],
                stockList:[],
                tabindex: 0,
                isLoadingStock:false,
                stockLoading:false,
                stockFinished:false,
            }
        },
        mounted() {
            this.getInfo();
            //uwStatisticAction('/site/siteInfo','站点信息')
        },
        methods:{
            getInfo() {
                getStationSellAndStockInfo(this.$route.query.stationNo).then(res=>{
                    if(res.code === 200){
                        console.log('数据111111', res.data)
                        this.stockList = res.data.stockList;
                        this.sellList = res.data.sellList;
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .siteInfo{
        height: 100%;
        background: #f5f5f5;
        font-size: 0.3rem;
        .echartsView{
            height: 200px;
            padding: 0.2rem 0 0.4rem 0;

        }
        .visit-hide{
            height: 1.74rem !important;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
            .tab-wrap{
                margin: 0.25rem 0.24rem 0 !important;
            }
        }
        .tabs-wrap{
            height: 2.26rem;
            justify-content: space-between;
            background: #fff;
            border-top-left-radius: 1.02rem;
            border-top-right-radius: 1.02rem;
            margin-bottom: -0.3rem;
            .tab-wrap{
                width: 3.39rem;
                height: 0.74rem;
                line-height: 0.74rem;
                text-align: center;
                background: #F5F5F5;
                color: #333;
                border-radius: 0.4rem;
                margin: 0.82rem 0.24rem 0;
                font-size: 0.3rem;
            }
            .active{
                background: #3F7C53;
                color: #fff;
            }
        }


        .visit-wrap{
            position: relative;
            .visitbg-img{
                width: 7.5rem;
            }
            .right-img{
                width: 0.4rem;
            }
            .visit-img{
                width: 1.46rem;
            }
            .visit-content{
                width: 6.9rem;
                height: 1.86rem;
                position: absolute;
                left: 0.3rem;
                top: 0.23rem;
                justify-content: space-between;
                align-items: center;
                padding: 0.3rem 0.48rem 0.3rem 0.4rem;
                .left{
                    color: #fff;
                    div{
                        font-size: 0.36rem;
                        margin-bottom: 0.16rem;
                    }
                }
            }
        }
        .zzScore{
            background-color: white;
            height: 160px;
            .zzScoreTop{
                justify-content: center;
                background-color: white;
                position: relative;
                z-index: 999;
                margin-bottom: -20px;
                .zzScoreTopTag{
                    width: 75px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    border-radius: 20px;
                    justify-content: space-around;
                    font-size: 10px;
                    .zzScoreTopTagRect{
                        width: 8px;height: 8px;border-radius: 2px;
                    }
                }
                .zzScoreTopTagMargin{
                    margin-left: 10px;
                }
            }
        }
        .item{
            .van-field{
                border-bottom: 0.01rem solid #f5f5f5;
            }
        }
        .mt2{
            height: 0.2rem;
            background: #f5f5f5;
        }
        .title {
            text-align: left;
            background: #FFFFFF;
            height: 0.8rem;
            line-height: 0.8rem;
            padding: 0 0.3rem;
            color: #333333;
            font-weight: bold;
            font-size: 15px;
        }
        div.title:before{
            width: 10px;
            height: 10px;
            color: #3F7C53;
            content:'| '
        }
        .village-info{
            background: #fff;
            color: #333333;
            font-size: 15px;
            padding: 0.3rem;
        }
        .score-wrap{
            padding: 0.3rem;
            background: #fff;
            margin-top: 0.2rem ;
            .score-item{
                flex:1;
                text-align: center;

            }
            .score{
                text-align: right;
                color: red;
                font-weight: bold;
                font-size: 0.3rem;
            }
            .tag{
                /*border:0.02rem solid #aaa;*/
            }
        }
        .item-title{
            background: #f5f5f5;
            height: 0.8rem;
            line-height: 0.8rem;
            padding: 0 0.2rem;
        }
        .item-content{
            background: #fff;
            padding: 0.1rem 0.2rem;
            font-size: 0.3rem;
            color: #333;
        }
        .VDS-ShadowBg{
            background: url("../../assets/img/common/shadowBg.png");
            background-size: 100% 100%;
        }

    }
    .mapDiv{
        background:rgba(0,0,0,0.3);
        padding: 10px;
        width: 375px;
        // height: 667px;
        height: 100vh;
        .el-vue-amap-container, .el-vue-amap-container .el-vue-amap{
            height: calc(100vh - 2rem);
        }
    }
    .close {
        text-align: right;
        margin-bottom: 14px;
        .van-icon {
            background: url("../../assets/img/close.png");
            background-size: 100% 100%;
            width: 24px;
            height: 24px;
        }
    }
</style>
<style>
    .siteInfo .van-tab__text{
        font-size: 15px;
    }
    .siteInfo .van-cell{
        min-height: 48px;
    }
    .siteInfo .van-cell__title{
        font-size: 15px;
        color: #333333;
    }
    .siteInfo .van-field__control {
        font-size: 15px;
    }
    .siteInfo .van-radio{
        font-size: 15px;
    }
    .siteInfo .van-field__clear, .van-field__right-icon{
        line-height: 0.35rem;
    }

</style>
<style lang="less">
    @import "../../style/putInto.less";

</style>
